
<template>
    <div class="bg">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" >
            <div>
                <div @click="addCustomer" class="add">新增联系人+</div>
                <div class="van-clearfix table-bg" >

                        <div class="van-clearfix">
                            <div class="table-header">
                                <van-row >
                                    <van-col span="7"><p class="table-header-left-label">分组</p></van-col>
                                    <van-col span="7"><p class="table-header-center-label">姓名</p></van-col>
                                    <van-col span="10"><p class="table-header-right-label">电话号码</p></van-col>
                                </van-row>
                            </div>
                            <van-list
                                    :error.sync="error"
                                    error-text="请求失败，点击重新加载"
                                    v-model="loading"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad"  >
                            <myCustomerCell
                                    v-for="(item,index) in infoList"
                                    :key="index"
                                    class="info"
                                    :info="item"
                                    :customerGroup="customerGroup"
                                    @click.native="customerDetail(item)"></myCustomerCell>
                            </van-list>
                        </div>

                </div>
            </div>
        </van-pull-refresh>

    </div>
</template>

<script>
    import myCustomerCell from "./stationAgentsubviews/myCustomerCell";
    import {Toast} from "vant";
    import {getDictByPage,getCustomerList} from "../../utils/api";
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        components: {
            myCustomerCell
        },
        name: "chooseCustomer",
        data() {
            return {
                searchValue:'',
                infoList:[],
                isLoading: false,
                loading: false,
                finished: false,
                pageIndex: 1,
                error:false,
                listHeight:null,
                customerGroup:[]
            }
        },
        mounted() {
            this.getPageInfo();
            uwStatisticAction('/chooseCustomer','选择客户')
        },
        methods: {
            getPageInfo () {
                this.customerGroup = JSON.parse(common.getItem('customerGroup'));
            },
            customerDetail(item){
                if (this.$route.query.type == 'addChoose'){
                    this.$router.replace({name:'addTransacrionDetails',query:{
                            'customerNo':item.customerNo,'customerName':item.name
                        }})
                    this.$router.go(-1)
                }else if (this.$route.query.type == 'editChoose'){
                    this.$router.replace({name:'editTransacrionDetails',query:{
                            'customerNo':item.customerNo,'customerName':item.name
                        }})
                    this.$router.go(-1)
                } else {
                    this.$router.push({name:'customerDetail',query:{'item':item,'customerGroup':this.customerGroup}})
                }

            },
            addCustomer(){
                this.$router.push({name:'addCustomer'})
            },
            onSearch(){
                console.log('search')
            },
            showDetail() {
                console.log('detail')
            },
            add() {
                console.log('add')
            },
            toGetCustomerList(){
                getCustomerList({
                    'name':'',
                    'pageIndex':this.pageIndex.toString(),
                    'pageSize':'10'
                }).then(res=>{
                    // 加载状态结束
                    this.loading = false;
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.finished = !res.data.pageModel.hasNextPage;

                            if (this.pageIndex == 1){
                                this.infoList = res.data.pageModel.list;
                            } else {
                                this.infoList = this.infoList.concat(res.data.pageModel.list);
                            }
                            if (!this.finished){
                                this.pageIndex++;
                            }
                            console.log('page',this.infoList)
                        }else {
                            this.error = true;
                        }
                    }else {
                        this.error = true;
                    }
                }).catch(()=>{
                    this.error = true;
                })
            },
            onLoad() {
                this.toGetCustomerList();
            },
            onRefresh() {
                this.infoList=[];
                this.isLoading = false;
                this.loading = true;
                this.finished=false;
                this.error = false;
                this.pageIndex = 1;
                this.onLoad();
            }

        }
    }
</script>

<style lang="less" scoped>
    .bg{
        background: #eeeeee;
        height: 100%;
        .flex{
            display: flex;
        }
        .add{
            margin: 0.2rem;
            background: #eeeeee;
            height: 1rem;
            line-height: 1rem;
            text-align: center;
            border: dashed 1px #999999
        }
        .table-bg{
            margin: 0 0.2rem 40px 0.2rem;
            .table-header{
                margin: 0 0.2rem;
                height: 1rem;
                .table-header-left-label{
                    text-align: left;
                    width: 1.2rem;
                    height:0.5rem;
                    line-height: 0.5rem;
                    margin-top: 0.4rem;
                    border-radius: 0.05rem;
                    color: black
                }
                .table-header-center-label{
                    text-align: left;
                    width: 1.2rem;
                    height:0.5rem;
                    line-height: 0.5rem;
                    margin-top: 0.4rem;
                    border-radius: 0.05rem;
                    color: black
                }
                .table-header-right-label{
                    text-align: left;
                    width: 1.2rem;
                    height:0.5rem;
                    line-height: 0.5rem;
                    margin-top: 0.4rem;
                    border-radius: 0.05rem;
                    color: black
                }

            }
        }
        .footer{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.88rem;
            .van-button{
                width: 100%;
            }
        }
    }





</style>
